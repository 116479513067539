.button {
  background-color: #F9B332;
  font-size: 22px;
  line-height: 1.36363636;
  font-family: inherit;
  cursor: pointer;
  color: #0C3358;
  padding: 10px 30px 10px 52px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: none;
  position: relative;
  transition: all 0.24s;
}
.button:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 52px;
  height: 100%;
  background-color: #0C3358;
  mask-size: auto 18px;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/chevron-right.svg);
  transition: all 0.24s;
}
.button:hover,
.button:focus {
  background-color: #0C3358;
  color: #F9B332;
}
.button:hover:before,
.button:focus:before {
  mask-position: 60% 50%;
  background-color: #F9B332;
}
@media (max-width: 767px) {
  .button {
    font-size: 18px;
    line-height: 1.44444444;
  }
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.navigation div.navi {
  float: left;
}
.navigation div.navi > .item {
  float: left;
}
.navigation div.navi > .item > .menu {
  display: block;
}
@media (min-width: 1280px) {
  .togglenavigation,
  .mobile-navigation {
    display: none;
  }
}
.navigation div.sub2 > .item {
  margin-right: 46px;
}
.navigation div.sub2 > .item.exit {
  margin-right: 0 !important;
}
.navigation div.sub2 > .item.cb-navi-target-pageColor--yellow > .menu:after {
  mask-image: url(/images/naviicon-c1.svg);
}
.navigation div.sub2 > .item.cb-navi-target-pageColor--yellow > .menu:hover,
.navigation div.sub2 > .item.cb-navi-target-pageColor--yellow > .menu:focus,
.navigation div.sub2 > .item.cb-navi-target-pageColor--yellow > .menu.path {
  color: #F9B332;
}
.navigation div.sub2 > .item.cb-navi-target-pageColor--yellow > .menu:hover:after,
.navigation div.sub2 > .item.cb-navi-target-pageColor--yellow > .menu:focus:after,
.navigation div.sub2 > .item.cb-navi-target-pageColor--yellow > .menu.path:after {
  background-color: #F9B332;
}
.navigation div.sub2 > .item.cb-navi-target-pageColor--blue > .menu:after {
  mask-image: url(/images/naviicon-c2.svg);
}
.navigation div.sub2 > .item.cb-navi-target-pageColor--blue > .menu:hover,
.navigation div.sub2 > .item.cb-navi-target-pageColor--blue > .menu:focus,
.navigation div.sub2 > .item.cb-navi-target-pageColor--blue > .menu.path {
  color: #1470B8;
}
.navigation div.sub2 > .item.cb-navi-target-pageColor--blue > .menu:hover:after,
.navigation div.sub2 > .item.cb-navi-target-pageColor--blue > .menu:focus:after,
.navigation div.sub2 > .item.cb-navi-target-pageColor--blue > .menu.path:after {
  background-color: #1470B8;
}
.navigation div.sub2 > .item.cb-navi-target-pageColor--gray > .menu:after {
  mask-image: url(/images/naviicon-c3.svg);
}
.navigation div.sub2 > .item.cb-navi-target-pageColor--gray > .menu:hover,
.navigation div.sub2 > .item.cb-navi-target-pageColor--gray > .menu:focus,
.navigation div.sub2 > .item.cb-navi-target-pageColor--gray > .menu.path {
  color: #726F6D;
}
.navigation div.sub2 > .item.cb-navi-target-pageColor--gray > .menu:hover:after,
.navigation div.sub2 > .item.cb-navi-target-pageColor--gray > .menu:focus:after,
.navigation div.sub2 > .item.cb-navi-target-pageColor--gray > .menu.path:after {
  background-color: #726F6D;
}
.navigation div.sub2 > .item.cb-navi-target-pageColor--darkblue > .menu:after {
  mask-image: url(/images/naviicon-c1.svg);
}
.navigation div.sub2 > .item.cb-navi-target-pageColor--darkblue > .menu:hover,
.navigation div.sub2 > .item.cb-navi-target-pageColor--darkblue > .menu:focus,
.navigation div.sub2 > .item.cb-navi-target-pageColor--darkblue > .menu.path {
  color: #726F6D;
}
.navigation div.sub2 > .item.cb-navi-target-pageColor--darkblue > .menu:hover:after,
.navigation div.sub2 > .item.cb-navi-target-pageColor--darkblue > .menu:focus:after,
.navigation div.sub2 > .item.cb-navi-target-pageColor--darkblue > .menu.path:after {
  background-color: #726F6D;
}
.navigation div.sub2 > .item > .menu {
  float: left;
  display: flex;
  align-items: flex-start;
  text-decoration: none;
  font-size: 22px;
  line-height: 1.27272727;
  font-weight: 500;
  padding-left: 38px;
  color: #0C3358;
  position: relative;
}
.navigation div.sub2 > .item > .menu:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 38px;
  height: 100%;
  background-color: #0C3358;
  mask-size: 28px 28px;
  mask-position: 0 50%;
  mask-repeat: no-repeat;
}
@media (max-width: 1599px) {
  .navigation div.sub2 > .item > .menu {
    font-size: 20px;
    line-height: 1.4;
  }
}
#home {
  transition: all 0.4s;
}
.cb-scroll-triggered--active #home {
  height: 64px;
}
.section--header .content {
  transition: all 0.4s;
}
.cb-scroll-triggered--active.section--header .content {
  margin: 15px 0;
}
.section--one .content {
  width: 1064px;
  max-width: calc(100% - 586px);
}
@media (max-width: 1599px) {
  .section--one .content {
    max-width: calc(100% - 460px);
  }
}
.area--four .unitTwo {
  display: flex;
  align-items: flex-start;
}
.area--four .unitTwo .unit__background {
  width: calc((100% - (714px + 40px)) + ((100vw - var(--deskWidth)) / 2));
  max-width: 1090px;
  flex-shrink: 0;
  height: 680px;
  min-height: 100%;
}
.area--four .unitTwo .unit__background * {
  height: 100%;
}
.area--four .unitTwo .unit__background img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.area--four .unitTwo .unit__background .placeholder {
  padding-bottom: 0;
}
.area--four .unitTwo .unit__content {
  width: 714px;
  flex-shrink: 0;
  margin-top: var(--altVerticalSpaceSmall);
  margin-bottom: var(--altVerticalSpaceSmall);
}
@media (max-width: 1810px) {
  .area--four .unitTwo .unit__background {
    width: calc((100% - (714px + 40px)) + var(--deskSpace));
  }
}
@media (max-width: 1599px) {
  .area--four .unitTwo .unit__content {
    width: 600px;
  }
  .area--four .unitTwo .unit__background {
    width: calc((100% - (600px + 40px)) + var(--deskSpace));
  }
}
.area--four .unitTwo--imageAlignRight {
  justify-content: flex-start;
}
.area--four .unitTwo--imageAlignRight .unit__background {
  order: 2;
  margin-left: 40px;
}
.area--four .unitTwo--imageAlignRight .unit__content {
  order: 1;
}
.area--four .unitTwo--imageAlignLeft {
  justify-content: flex-end;
}
.area--four .unitTwo--imageAlignLeft .unit__background {
  margin-right: 40px;
}
.area--four .unitThree {
  width: calc((100% / 3) - 60px);
}
/*# sourceMappingURL=./screen-large.css.map */